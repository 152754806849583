import { default as envie_45sua_45historiarPElvCqFZhMeta } from "D:/www/aparecidanp/aparecidanp.com.br/pages/contato/envie-sua-historia.vue?macro=true";
import { default as index0BAWTtKqhZMeta } from "D:/www/aparecidanp/aparecidanp.com.br/pages/contato/index.vue?macro=true";
import { default as trabalheXAdoNFQfYbMeta } from "D:/www/aparecidanp/aparecidanp.com.br/pages/contato/trabalhe.vue?macro=true";
import { default as _91id_93pkyf90jdmAMeta } from "D:/www/aparecidanp/aparecidanp.com.br/pages/educacao/[id].vue?macro=true";
import { default as _91id_93ABQhp6V2qmMeta } from "D:/www/aparecidanp/aparecidanp.com.br/pages/eventos/[id].vue?macro=true";
import { default as indexIx3aBEoTfNMeta } from "D:/www/aparecidanp/aparecidanp.com.br/pages/eventos/index.vue?macro=true";
import { default as _91id_93zbwSM0VtnpMeta } from "D:/www/aparecidanp/aparecidanp.com.br/pages/galeria/[id].vue?macro=true";
import { default as indexjKr5c2ATuMMeta } from "D:/www/aparecidanp/aparecidanp.com.br/pages/galeria/index.vue?macro=true";
import { default as indexIQHY59U8r5Meta } from "D:/www/aparecidanp/aparecidanp.com.br/pages/index.vue?macro=true";
import { default as _91id_93ve0lnCqGtWMeta } from "D:/www/aparecidanp/aparecidanp.com.br/pages/mantenedora/[id].vue?macro=true";
import { default as _91id_93k9fnN8J53BMeta } from "D:/www/aparecidanp/aparecidanp.com.br/pages/material/[id].vue?macro=true";
import { default as index55NV9md4CwMeta } from "D:/www/aparecidanp/aparecidanp.com.br/pages/material/index.vue?macro=true";
import { default as _91id_93jlzXxiZz04Meta } from "D:/www/aparecidanp/aparecidanp.com.br/pages/noticias/[id].vue?macro=true";
import { default as indexlWL8xGoEfuMeta } from "D:/www/aparecidanp/aparecidanp.com.br/pages/noticias/index.vue?macro=true";
import { default as _91id_93hhenfcOW4aMeta } from "D:/www/aparecidanp/aparecidanp.com.br/pages/sobre/[id].vue?macro=true";
export default [
  {
    name: "contato-envie-sua-historia",
    path: "/contato/envie-sua-historia",
    component: () => import("D:/www/aparecidanp/aparecidanp.com.br/pages/contato/envie-sua-historia.vue").then(m => m.default || m)
  },
  {
    name: "contato",
    path: "/contato",
    component: () => import("D:/www/aparecidanp/aparecidanp.com.br/pages/contato/index.vue").then(m => m.default || m)
  },
  {
    name: "contato-trabalhe",
    path: "/contato/trabalhe",
    component: () => import("D:/www/aparecidanp/aparecidanp.com.br/pages/contato/trabalhe.vue").then(m => m.default || m)
  },
  {
    name: "educacao-id",
    path: "/educacao/:id()",
    component: () => import("D:/www/aparecidanp/aparecidanp.com.br/pages/educacao/[id].vue").then(m => m.default || m)
  },
  {
    name: "eventos-id",
    path: "/eventos/:id()",
    component: () => import("D:/www/aparecidanp/aparecidanp.com.br/pages/eventos/[id].vue").then(m => m.default || m)
  },
  {
    name: "eventos",
    path: "/eventos",
    component: () => import("D:/www/aparecidanp/aparecidanp.com.br/pages/eventos/index.vue").then(m => m.default || m)
  },
  {
    name: "galeria-id",
    path: "/galeria/:id()",
    component: () => import("D:/www/aparecidanp/aparecidanp.com.br/pages/galeria/[id].vue").then(m => m.default || m)
  },
  {
    name: "galeria",
    path: "/galeria",
    component: () => import("D:/www/aparecidanp/aparecidanp.com.br/pages/galeria/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("D:/www/aparecidanp/aparecidanp.com.br/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "mantenedora-id",
    path: "/mantenedora/:id()",
    component: () => import("D:/www/aparecidanp/aparecidanp.com.br/pages/mantenedora/[id].vue").then(m => m.default || m)
  },
  {
    name: "material-id",
    path: "/material/:id()",
    component: () => import("D:/www/aparecidanp/aparecidanp.com.br/pages/material/[id].vue").then(m => m.default || m)
  },
  {
    name: "material",
    path: "/material",
    component: () => import("D:/www/aparecidanp/aparecidanp.com.br/pages/material/index.vue").then(m => m.default || m)
  },
  {
    name: "noticias-id",
    path: "/noticias/:id()",
    component: () => import("D:/www/aparecidanp/aparecidanp.com.br/pages/noticias/[id].vue").then(m => m.default || m)
  },
  {
    name: "noticias",
    path: "/noticias",
    component: () => import("D:/www/aparecidanp/aparecidanp.com.br/pages/noticias/index.vue").then(m => m.default || m)
  },
  {
    name: "sobre-id",
    path: "/sobre/:id()",
    component: () => import("D:/www/aparecidanp/aparecidanp.com.br/pages/sobre/[id].vue").then(m => m.default || m)
  }
]